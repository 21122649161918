<template>
  <v-content :class="isDrawer ? '' : 'pa-0'">
    <router-view />
    <dashboard-core-footer />
  </v-content>
</template>

<script>
export default {
  name: "DashboardCoreView",
  components: {
    DashboardCoreFooter: () => import('./Footer'),
  },
  computed: {
    isDrawer() {
      return this.$route.meta.isDrawer;
    }
  }
};
</script>
